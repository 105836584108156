﻿import { events } from './events';
import { dialogs } from './dialogs';

import PerfectScrollbar from 'perfect-scrollbar';
import NProgress from 'nprogress';

let scrollbar: PerfectScrollbar = null;

let navMutObserver: MutationObserver = null;

export const app = {
    preInit: () => {
        events.on<string>('ApplicationError')
            .subscribe(error => {
                dialogs.error(error);
            });

        document.addEventListener("click", function (event) {
            const element = event.target as HTMLAnchorElement;
            if (element && element.tagName.toLowerCase() === "a" &&
                element.hasAttribute("href") &&
                element.getAttribute("href").indexOf("#") === 0) {
                element.href = location.pathname + element.getAttribute("href");
            }
        });
    },
    init: () => {
        NProgress.configure({
            showSpinner: false,
            speed: 200,
            trickleSpeed: 100
        });
    },
    initScrollbar: () => {
        if (app._scrollBarInit) {
            return;
        }

        app._scrollBarInit = true;
    },
    initNav: () => {
        if (!navMutObserver) {
            navMutObserver = new MutationObserver(mutations => {
                let foundNew = false;

                for (const mut of mutations) {
                    for (const node of Array.from(mut.addedNodes)) {
                        if (node instanceof HTMLElement && node.classList.contains('collapsible-header')) {
                            foundNew = true;

                            break;
                        }
                    }

                    if (foundNew) {
                        break;
                    }
                }

                if (foundNew) {
                    ($ as any)('.collapsible').collapsible();
                }
            });

            navMutObserver.observe(document.body, {
                childList: true,
                subtree: true
            });
        }

        ($ as any)('.collapsible').collapsible();

        if (!app._navInit) {
            ($ as any)(".button-collapse").sideNav2({
                mode: 'push',
                showOverlay: false
            });

            if (window.innerWidth <= 1440) {
                const closeButton = document.createElement("a");
                closeButton.innerHTML = '<i class="fas fa-times"></i>';

                $("#slide-out").prepend(closeButton);

                $(closeButton).click(() => {
                    $("a[data-activates='slide-out']").trigger('click');
                });
            }
        }

        if (scrollbar == null) {
            const sideNavScrollbar = document.querySelector<HTMLElement>('.custom-scrollbar');

            scrollbar = new PerfectScrollbar(sideNavScrollbar);
        } else if (scrollbar != null) {
            scrollbar.update();
        }

        app._navInit = true;
    },
    toggleSidebar: () => {
        $("body").toggleClass("sidebar-lg-show");
    },
    toggleMobileSidebar: () => {
        $("body").toggleClass("sidebar-sm-show");
    },
    startProgress: () => {
        NProgress.start();
    },
    stopProgress: () => {
        NProgress.done();
    },
    openUrl: (url: string, useBase: boolean) => {
        if (useBase) {
            window.open(`${import.meta.env.VITE_BASE_URL}${url}`);
        } else {
            window.open(url, '_blank');
        }
    },
    _scrollBarInit: false,
    _navInit: false
};