﻿import dayjs from 'dayjs';
import { DotNetReferenceType } from './dotnet';

export const utils = {
    parseDate: (input: string) => {
        const m = dayjs(input);

        if (!m.isValid()) {
            return null;
        }

        return m.unix();
    },
    getFileInputData: (el: HTMLInputElement, dotnetHelper: DotNetReferenceType) => {
        if (el && el.files) {
            const file = el.files[0];

            if (file) {
                const reader = new FileReader();

                reader.onload = function () {
                    dotnetHelper.invokeMethodAsync('OnFileChanged', reader.result);
                };
                reader.onerror = function (e) {
                    console.error(e);
                };

                reader.readAsDataURL(file);
            }
        }
    },
    saveAsFile: (filename: string, bytesBase64: string) => {
        var link = document.createElement('a');
        link.download = filename;
        link.href = "data:application/octet-stream;base64," + bytesBase64;
        document.body.appendChild(link); // Needed for Firefox
        link.click();
        document.body.removeChild(link);
    },
    getElementValue: (element: Element | string) => {
        if (element instanceof Element) {
            return $(element).val();
        }

        return $(`#${element}`).val();
    },
    setElementValue: (element: Element | string, value) => {
        if (element instanceof Element) {
            $(element).val(value);
        } else {
            $(`#${element}`).val(value);
        }
    },
    addClass: (el: Element, cssClass: string) => {
        el?.classList.add(cssClass);
    },
    removeClass: (el: Element, cssClass: string) => {
        el?.classList.remove(cssClass);
    },
    addStyle: (el: HTMLElement, styleKey: string, styleVal: string) => {
        el?.style.setProperty(styleKey, styleVal);
    },
    removeStyle: (el: HTMLElement, styleKey: string) => {
        el?.style.removeProperty(styleKey);
    },
};