import { authentication } from "./authentication";
import { DotNetReferenceType } from "./dotnet";

export type UploadOptions = {
    contentType: string;
    cacheControl: number;
}

type UploadProgress = {
    uploadedBytes: number;
    totalBytes: number;
};

export const http = {
    fetch: async <T>(info: RequestInfo, init?: RequestInit): Promise<T> => {
        const resp = await fetch(info, {
            ...(init || {}),
            headers: {
                'Authorization': `Bearer ${authentication.getToken()}`,
                ...(init?.headers || {})
            }
        });

        return await resp.json();
    },
    get: <T>(url: string): Promise<T> => {
        return http.fetch<T>(url, {
            method: 'GET'
        });
    },
    post: <T>(url: string, obj: any): Promise<T> => {
        return http.fetch<T>(url, {
            method: 'POST',
            body: JSON.stringify(obj)
        })
    },
    put: <T>(url: string, obj: any): Promise<T> => {
        return http.fetch<T>(url, {
            method: 'PUT',
            body: JSON.stringify(obj)
        })
    },
    upload: async (url: string, method: string, fileEl: HTMLInputElement, options: UploadOptions,
        dotnet: DotNetReferenceType) => {
        const file = fileEl.files[0];

        if (!file) {
            throw new Error('no file provided');
        }

        return await new Promise<boolean>((resolve, reject) => {
            const xhr = new XMLHttpRequest();

            xhr.upload.onload = () => {
                resolve(true);
            };

            xhr.upload.ontimeout = () => {
                reject('Request timed out');
            };

            xhr.upload.onabort = () => {
                reject('Request aborted');
            };

            xhr.upload.onprogress = (event) => {
                const progress: UploadProgress = {
                    totalBytes: event.total,
                    uploadedBytes: event.loaded
                };

                dotnet.invokeMethodAsync('Progress', progress);
            };
            xhr.upload.onerror = () => {
                reject(`An error occurred while uploading, status code: ${xhr.status}`);
            };

            xhr.open(method, url);
            xhr.setRequestHeader('Content-Type', options.contentType);
            xhr.setRequestHeader('Cache-Control', options.cacheControl?.toString() || '2592000');

            xhr.send(file);
        });
    }
};