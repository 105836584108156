﻿import { DotNetReferenceType } from "./dotnet";

import 'typeahead.js';

export const typeahead = {
    init: (id: string, key: string, dotnetHelper: DotNetReferenceType, options?: Twitter.Typeahead.Options) => {
        options = { ...options, minLength: 3, highlight: true };

        $(`#${id}`).typeahead(options, {
            display: (suggestion) => {
                return suggestion[key];
            },
            source: (query, syncResults, asyncResults) => {
                dotnetHelper.invokeMethodAsync<any[]>('PopulateSuggestions', query)
                    .then(results => {
                        asyncResults(results);
                    });
            }
        });

        $(`#${id}`).bind('typeahead:select', (ev, suggestion) => {
            dotnetHelper.invokeMethodAsync('OnSuggestionSelected', suggestion);
        });
    },
    destroy: (id: string) => {
        $(`#${id}`).typeahead('destroy');
    },
};