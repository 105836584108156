﻿import FroalaEditor from 'froala-editor';
import { authentication } from './authentication';
import { DotNetReferenceType } from './dotnet';

const editorCache: { [id: string]: { initialized: boolean, editor: FroalaEditor } } = {};

export const editor = {
    init: function (id: string, novelId: number, content: string, dotnetHelper: DotNetReferenceType, options) {
        if (editorCache[id] && editorCache[id].initialized) {
            editor.destroy(id);
        } else if (editorCache[id]) {
            return;
        }

        const events = editor._getEvents(id, dotnetHelper);

        const opts = Object.assign({}, editor._createFroalaOptions(novelId), options, {
            events: events
        });

        editorCache[id] = { initialized: false, editor: null };

        $("#" + id).html(content);

        const froalaEditor = new FroalaEditor("#" + id, opts);

        editorCache[id].editor = froalaEditor;

        //($ as any)("#" + id).froalaEditor(opts);

        //for (let evt in events) {
        //    if (events.hasOwnProperty(evt)) {
        //        $("#" + id).on(evt, events[evt]);
        //    }
        //}
    },
    destroy: (id: string) => {
        if (editorCache[id] && editorCache[id].initialized) {
            editorCache[id].editor.destroy();

            delete editorCache[id];

            return true;
        }

        return false;
    },
    setContent: (id: string, content: string) => {
        if (editorCache[id] && editorCache[id].initialized) {
            editorCache[id].editor.html.set(content);
        }
    },
    _getEvents: (id: string, dotnetHelper: DotNetReferenceType) => {
        return {
            'initialized': (e, editor) => {
                //if (this.atConfig) {
                //    editor.$el
                //        .atwho(this.atConfig)
                //        .on('inserted.atwho',
                //            function () {
                //                editor.$el.find('.atwho-inserted')
                //                    .removeAttr('contenteditable')
                //                    .removeAttr('data-atwho-at-query');
                //            });

                //    editor.events.on('keydown',
                //        function (e) {
                //            if (e.which == (<any>$).FroalaEditor.KEYCODE.ENTER && editor.$el.atwho('isSelecting')) {
                //                return false;
                //            }
                //        },
                //        true);
                //}

                editorCache[id].initialized = true;

                dotnetHelper.invokeMethodAsync('Init');
            },
            'paste.afterCleanup': function (clipboard_html) {
                const div = $("<div />").html(clipboard_html);

                div.find('br').remove();
                div.find('span,p').each((i, e) => {
                    const el = $(e);

                    el.css('margin-top', '');
                    el.css('margin-bottom', '');
                    el.css('font-size', '');
                    el.css('font-family', '');
                    el.css('line-height', '');

                    const backgroundColor = el.css('background-color');

                    if (backgroundColor === "rgb(255, 255, 255)" || backgroundColor == "#ffffff") {
                        el.css('background-color', '');
                    }
                });

                return div.html();
            },
            'image.error': (e, editor, error, response) => {
                console.error(error, response);
            },
            'image.uploaded': (e, editor, response) => {
                // console.log(response);
            },
            'contentChanged': function () {
                const html = this.html.get();

                dotnetHelper.invokeMethodAsync('ContentChanged', html);
            }
        };
    },
    _createFroalaOptions: (novelId?: number) => {
        const withNovelId = !!novelId ? { novelId } : {};
        const defaultToolbarButtons = {
            moreText: {
                'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
            },
            moreParagraph: {
                'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
            },
            moreRich: {
                'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR', 'spoilerButton']
            },
            moreMisc: {
                'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                'align': 'right',
                'buttonsVisible': 2
            }
        };

        return {
            key: import.meta.env.VITE_FROALA_KEY,
            toolbarButtons: defaultToolbarButtons,
            toolbarButtonsSM: {
                ...Object.keys(defaultToolbarButtons).reduce((p, c) => {
                    p[c] = { ...defaultToolbarButtons[c], buttonsVisible: 2 };

                    return p;
                }, {})
            },
            toolbarButtonsXS: {
                ...Object.keys(defaultToolbarButtons).reduce((p, c) => {
                    p[c] = { ...defaultToolbarButtons[c], buttonsVisible: 0 };

                    return p;
                }, {}),
                moreMisc: {
                    ...defaultToolbarButtons.moreMisc,
                    buttonsVisible: 2
                }
            },
            pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI', 'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste', 'spoilerPlugin'],
            toolbarStickyOffset: 50,
            htmlAllowedTags: ['.*'],
            htmlAllowedAttrs: ['.*'],
            htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'hr', 'i', '.fas'],
            htmlRemoveTags: [],
            pasteDeniedAttrs: ['id', 'class'],
            linkText: true,
            tabSpaces: 4,
            requestHeaders: {
                'Authorization': `Bearer ${authentication.getToken()}`
            },
            fileUploadURL: `${import.meta.env.VITE_BASE_URL}/api/admin/upload`,
            fileUploadParams: {
                type: 'file',
                ...withNovelId
            },
            fileMaxSize: 20 * 1024 * 1024, //20 MB
            fileAllowedTypes: ['*'],
            imageUploadURL: `${import.meta.env.VITE_BASE_URL}/api/admin/upload`,
            imageUploadParams: {
                type: 'image',
                ...withNovelId
            },
            imageMaxSize: 3 * 1024 * 1024, //3 MB
            imageManagerLoadURL: `${import.meta.env.VITE_BASE_URL}/api/admin/upload/images`,
            videoUploadURL: `${import.meta.env.VITE_BASE_URL}/api/admin/upload`,
            imageManagerLoadMethod: "GET",
            imageManagerLoadParams: withNovelId,
            imageManagerDeleteURL: `${import.meta.env.VITE_BASE_URL}/api/admin/upload`,
            imageManagerDeleteMethod: "DELETE",
            imageManagerDeleteParams: withNovelId,
            videoUploadParams: {
                type: 'media',
                ...withNovelId
            },
            videoMaxSize: 1024 * 1024 * 30, //30 MB,
            linkList: [
                {
                    text: 'Wuxiaworld',
                    href: 'https://www.wuxiaworld.com',
                    target: '_blank'
                }
            ]
        };
    }
}