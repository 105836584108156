import Push, { PushNotificationParams } from 'push.js';
import { DotNetReferenceType } from './dotnet';

type PushOptions = PushNotificationParams & {
};

let helper: DotNetReferenceType = null;

const createPushOptions = (id: string, options: PushOptions): PushNotificationParams => {
    const {
        ...opts
    } = options || {} as PushNotificationParams;

    return {
        ...opts,
        onClick: function () {
            window.focus();
            this.close();

            helper?.invokeMethodAsync('PushOnClick', id);
        },
        onError: () => {
            helper?.invokeMethodAsync('PushOnError', id);
        }
    };
};

export const push = {
    init: (dotnetHelper: DotNetReferenceType) => {
        helper = dotnetHelper;

        Push.config({
            serviceWorker: './serviceWorker.min.js'
        });
    },
    hasPermission: () => {
        return Push.Permission.has();
    },
    request: () => {
        if (!Push.Permission.has()) {
            Push.Permission.request(() => {
                helper?.invokeMethodAsync('PushOnPermission', true);
            }, () => {
                helper?.invokeMethodAsync('PushOnPermission', false);
            });
        }
    },
    create: (id: string, title: string, options: PushOptions) => {
        if (!push.hasPermission()) {
            return Promise.resolve(null);
        }

        return Push.create(title, createPushOptions(id, options));
    }
};