import type { Calendar } from '@fullcalendar/core';
import dayjs from 'dayjs';
import tippy from 'tippy.js';
import { DotNetReferenceType } from './dotnet';

const calendarCache: { [id: string]: Calendar } = {};

type CalendarEvent = {
    id: string;
    title: string;
    description: string;
    start: string;
    end: string;
    color: string;
};

export const calendar = {
    init: async (id: string, dotnet: DotNetReferenceType) => {
        await import('@fullcalendar/core/vdom');
        const Calendar = (await import('@fullcalendar/core')).Calendar;
        const dayGridPlugin = (await import('@fullcalendar/daygrid')).default;

        const el = document.getElementById(id);

        const cal = new Calendar(el, {
            timeZone: 'UTC',
            plugins: [dayGridPlugin],
            initialView: 'dayGridMonth',
            navLinks: true,
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,dayGridWeek,dayGridDay'
            },
            events: async info => {
                const start = dayjs(info.start).unix();
                const end = dayjs(info.end).unix();

                const events = await dotnet.invokeMethodAsync<CalendarEvent[]>('LoadEvents', start, end);

                return events.map(e => {
                    const {
                        id,
                        title,
                        description,
                        start,
                        end,
                        color,
                        ...rest
                    } = e;

                    return {
                        id,
                        title,
                        description,
                        start,
                        end,
                        color,
                        extendedProps: rest
                    }
                });
            },
            eventDidMount: (info) => {
                tippy(info.el, {
                    content: info.event.extendedProps.description
                });
            },
            defaultTimedEventDuration: '00:00',
            views: {
                // dayGridMonth: {
                //     dayMaxEvents: 15
                // }
            },
            // moreLinkClick: 'day'
        });

        calendarCache[id] = cal;

        cal.render();
    },
    destroy: (id: string) => {
        calendarCache[id]?.destroy();

        delete calendarCache[id];
    }
}