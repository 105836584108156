﻿import { DotNetReferenceType } from "./dotnet";

import $ from 'jquery';

export const trees = {
    init: async (id: string, dotnetHelper: DotNetReferenceType, options) => {
        // @ts-ignore
        await import('jstree');

        const opts = Object.assign({}, {
            'core': {
                'check_callback': function (operation, node, node_parent, node_position, more) {
                    return operation === 'move_node' && node.data.parentId === Number(node_parent.id);
                },
                'data': function (obj, callback: (data) => void) {
                    dotnetHelper.invokeMethodAsync('TreeAjax')
                        .then(data => {
                            callback.call(this, data);
                        });
                }
            } as JSTreeStaticDefaultsCore,
            'plugins': ["dnd"]
        } as JSTreeStaticDefaults, options);

        $(`#${id}`).jstree(opts);

        $(`#${id}`).on("changed.jstree", function (e, data) {
            dotnetHelper.invokeMethodAsync('SelectTreeNode', data.node?.original);
        });

        $(`#${id}`).on("move_node.jstree", function (e, data) {
            const parentNode = $(`#${id}`).jstree('get_node', data.parent);
            const nodes = [];

            for (let child of parentNode.children) {
                const childNode = $(`#${id}`).jstree('get_node', child);

                nodes.push(childNode.original);
            }

            dotnetHelper.invokeMethodAsync('ReorderNodes', nodes);
        });
    },
    destroy: (id: string) => {
        $(`#${id}`).off('changed.jstree');
        $(`#${id}`).jstree('destroy');
    },
    refresh: (id: string) => {
        $(`#${id}`).jstree('refresh');
    }
};