import { Column, Row } from "./data";
import { DotNetReferenceType } from "./dotnet";
import Mustache from 'mustache';

const template = `
<thead>
    <tr>
        {{#columns}}
        {{#visible}}
            <th>{{title}}</th>
        {{/visible}}
        {{/columns}}
    </tr>
    <tbody>
        {{#rows}}
            <tr>
                {{#cellInfos}}
                    <td>
                        <div class="{{class}}">
                            {{#getData}}
                                {{data}}
                            {{/getData}}
                        </div>
                    </td>
                {{/cellInfos}}
            </tr>
        {{/rows}}
    </tbody>
</thead>
`;

export const tables = {
    init: (id: string, dotnetHelper: DotNetReferenceType, columns: Column[], rows: Row[]) => {
        const html = Mustache.render(template, {
            columns,
            rows,
            getData: function () {
                return (text: string, render) => {
                    let html = text;

                    if (this.unescapeHtml) {
                        html = text.replace(/{{(.+)}}/g, '{{{$1}}}');
                    }

                    return render(html);
                }
            }
        });

        $(`#${id}`).html(html);
    },
    destroy: (id: string) => {
    }
};