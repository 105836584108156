
import type { Chart, ChartData, ChartOptions, ChartTypeRegistry } from 'chart.js';
import * as ChartJs from 'chart.js';
import 'chartjs-adapter-date-fns';

type ChartOpts = {
    type: keyof ChartTypeRegistry;
    data: ChartData;
    options: ChartOptions;
};

const chartCache: { [id: string]: Chart } = {};

export const charts = {
    init: async (id: string, options: ChartOpts) => {
        const ChartPlugins = [ChartJs.BarController, ChartJs.BarElement, ChartJs.LineController, ChartJs.LineElement, ChartJs.PointElement, ChartJs.LinearScale, ChartJs.Title, ChartJs.Tooltip, ChartJs.CategoryScale, ChartJs.TimeScale,
        ChartJs.Legend];

        const Chart = ChartJs.Chart;
        Chart.register(...ChartPlugins);

        charts.destroy(id);

        const el = document.getElementById(id) as HTMLCanvasElement;
        const ctx = el.getContext('2d');

        const chart = new Chart(ctx, {
            plugins: ChartPlugins,
            type: options.type,
            data: options.data,
            options: options.options,
        });

        chartCache[id] = chart;
    },
    update: (id: string, data: ChartData) => {
        const chart = chartCache[id];

        if (chart) {
            Object.assign(chart.data, data);

            chart.update();

            return true;
        }

        return false;
    },
    destroy: (id: string) => {
        chartCache[id]?.destroy();

        delete chartCache[id];
    }
};