import { storage } from './storage';

enum Themes {
    Light = 'LIGHT',
    Dark = 'DARK'
};

declare var THEMES: { [type in Themes]: string };

const ThemeStorageKey = 'theme';

export const theme = {
    getTheme: () => {
        return storage.getItem(ThemeStorageKey);
    },
    setTheme: (type: string) => {
        storage.setItem(ThemeStorageKey, type);

        const el = document.getElementById('theme') as HTMLLinkElement;

        const typeProp = type.toUpperCase();
        const theme = THEMES[typeProp];

        el.href = theme || THEMES.LIGHT;

        for (let theme of Object.entries(Themes)) {
            document.body.classList.remove(`theme-${theme[1].toLowerCase()}`);
        }

        document.body.classList.add(`theme-${type}`);
    }
};