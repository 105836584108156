import { DotNetReferenceType, DotNet } from "./dotnet";

type AudiobookChapter = {
    name: string;
    start: number;
    duration: number;
};

type CreateAudiobookOptions = {
    title: string;
    author: string;
    outputFormat: string;
    bitrate?: number;
};

const createAudiobookMetadata = (options: CreateAudiobookOptions, chapters: AudiobookChapter[]) => {
    const chapterMetadata = chapters.map(c => `
[CHAPTER]
TIMEBASE=1/1
START=${c.start}
END=${c.start + c.duration}
title=${c.name}
`).join('\n');

    return `
;FFMETADATA1
title=${options.title}
artist=${options.author || 'Wuxiaworld'}
album=${options.title}
genre=Audiobooks
track=1/1
media_type=2

${chapterMetadata}
`;
};

const readFile = (file: File) => {
    return new Promise<ArrayBuffer>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = ev => {
            const result = ev.target.result;

            if (result instanceof ArrayBuffer) {
                resolve(result);
            } else {
                reject('Result not Arraybuffer')
            }
        };

        fileReader.onerror = e => {
            reject(e.target.error);
        }

        fileReader.readAsArrayBuffer(file);
    });
}

const getAudioDuration = (file: File) => {
    return new Promise<number>((resolve, reject) => {
        const objectUrl = URL.createObjectURL(file);

        const audio = document.createElement('audio');
        audio.addEventListener('loadedmetadata', (e) => {
            resolve(audio.duration);
        }, true);

        audio.src = objectUrl;
    });
}

export const audiobooks = {
    init: async () => {
    },
    createAudiobookCommand: async (fileEl: HTMLInputElement, coverFileRef: HTMLInputElement, options: CreateAudiobookOptions = {
        title: 'Audiobook',
        outputFormat: 'm4b',
        author: 'Wuxiaworld'
    }) => {
        const { outputFormat, bitrate } = options;

        const sortedFiles = Array.from(fileEl.files)
            .map(file => {
                const [match] = file.name.match(/\d+/g);

                return {
                    file,
                    number: parseInt(match)
                };
            })
            .sort((a, b) => a.number - b.number);

        const inputPaths: string[] = [];
        const chapters: AudiobookChapter[] = [];

        let start = 0;

        for (const { file } of sortedFiles) {
            inputPaths.push(`file '${file.name}'`);

            const duration = await getAudioDuration(file);

            chapters.push({
                name: file.name.replace(/\.[^/.]+$/, ""),
                start,
                duration
            })

            start += duration;
        }

        const additionalArgs = [];
        const formats = sortedFiles.map(sf => sf.file.name.split('.').pop());
        const [firstFormat] = formats;

        if (!formats.some(format => format !== firstFormat) && firstFormat === outputFormat) {
            additionalArgs.push('-c:a', 'copy')
        } else if (outputFormat === 'm4a' || outputFormat === 'm4b') {
            additionalArgs.push('-c:a', 'libfdk_aac');
        }

        if (bitrate) {
            additionalArgs.push('-b:a', `${bitrate}k`)
        }

        const outputFileName = `output.${outputFormat}`;

        let tmp = 0;

        const getTempFile = (increment: boolean = true) => `tmp-${increment ? ++tmp : tmp}.${outputFormat}`;

        const JSZip = (await import('jszip')).default;

        const zip = new JSZip();

        const commands = [
            `-y -f concat -safe 0 -i concat_list.txt ${additionalArgs.join(' ')} ${getTempFile()}`,
            `-y -f ffmetadata -i FFMETADATAFILE -i ${getTempFile(false)} -c copy -map_metadata 0 -map_chapters 0 ${getTempFile()}`
        ];

        const additionalCommands: string[] = [];

        if (coverFileRef.files.length === 1) {
            const coverFile = coverFileRef.files[0];
            const coverFileBuffer = await coverFile.arrayBuffer();

            zip.file(coverFile.name, coverFileBuffer);

            if (outputFormat === 'm4a' || outputFormat === 'm4b') {
                additionalCommands.push(`AtomicParsley ${getTempFile(false)} --artwork "${coverFile.name}" --output ${getTempFile()}`);
            } else {
                commands.push(`-y -i ${getTempFile(false)} -i "${coverFile.name}" -map 0:0 -map 1:0 -c copy -id3v2_version 3 ${getTempFile()}`);
            }
        }

        zip.file('concat_list.txt', inputPaths.join('\n'));
        zip.file('FFMETADATAFILE', createAudiobookMetadata(options, chapters));

        const tmpFiles: string[] = [];

        for (let i = 1; i <= tmp; i++) {
            tmpFiles.push(`tmp-${i}.${outputFormat}`);
        }

        const [finalOutputFile] = tmpFiles.slice(-1);

        const ffmpegCommands = commands.map(command => `ffmpeg ${command}`)
            .join('\n');

        const batCommandStr = `
${ffmpegCommands}
${additionalCommands}

move ${finalOutputFile} ${outputFileName}
${tmpFiles.slice(0, -1).map(tf => `del ${tf}`).join('\n')}
            `;

        const shCommand = `
#/bin/bash
${ffmpegCommands}
${additionalCommands}

mv ${finalOutputFile} ${outputFileName}
${tmpFiles.slice(0, -1).map(tf => `rm ${tf}`).join('\n')}
`;

        zip.file('export.bat', batCommandStr.replaceAll(/(ffmpeg|AtomicParsley)/g, '$1.exe'));
        zip.file('export.sh', shCommand);

        const blob = await zip.generateAsync({ type: 'blob' });

        const link = document.createElement('a');
        link.download = `${options.title}.zip`;
        link.href = URL.createObjectURL(blob);

        document.body.appendChild(link); // Needed for Firefox
        link.click();
        document.body.removeChild(link);
    }
};