﻿import MarkdownIt from 'markdown-it';
import TurndownService from 'turndown';
import * as turndownPluginGfm from 'turndown-plugin-gfm';

export const markdown = {
    toHtml: function (content: string, options: MarkdownIt.Options) {
        const opts = Object.assign<MarkdownIt.Options, MarkdownIt.Options>({
            html: false,
            breaks: true
        }, options);

        const markdown = new MarkdownIt('commonmark', opts);

        return markdown.render(content);
    },
    toMarkdown: function (content: string, options: TurndownService.Options) {
        const turndownService = new TurndownService(options);
        turndownService.use(turndownPluginGfm.gfm);

        return turndownService.turndown(content);
    }
};