﻿import bootbox from 'bootbox';
import { DotNetReferenceType } from './dotnet';

export const dialogs = {
    alert: (message: string) => {
        bootbox.alert(message);
    },
    error: (message: string) => {
        bootbox.alert({
            title: "Error",
            message: message,
            buttons: {
                ok: {
                    label: 'Ok',
                    className: 'btn-primary'
                }
            }
        });
    },
    confirm: (message: string, helper: DotNetReferenceType) => {
        bootbox.confirm(message, function (result) {
            helper.invokeMethodAsync('Callback', result);
        });
    },
    prompt: (message: string, content: string, helper: DotNetReferenceType, options) => {
        const opts = Object.assign({}, {
            title: message,
            value: content,
            callback: function (result) {
                helper.invokeMethodAsync('Callback', result);
            }
        }, options);

        bootbox.prompt(opts);
    }
};