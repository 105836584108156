﻿import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DotNetReferenceType } from './dotnet';

export interface BroadcastEvent {
    type: string;
    data: any;
}

export const events = {
    broadcast: (type: string, data?: any) => {
        events._eventBus.next({ type, data });
    },
    on: <T>(type: string): Observable<T> => {
        return events._eventBus.asObservable()
            .pipe(
                filter(f => f.type === type),
                map(m => <T>m.data)
            );
    },
    subscribe: (type: string, helper: DotNetReferenceType) => {
        return events.on(type)
            .subscribe(data => {
                helper.invokeMethodAsync('Callback', data);
            });
    },
    _eventBus: new Subject<BroadcastEvent>()
};