﻿import drag from 'dragula';
import { DotNetReferenceType } from './dotnet';

const drakeCache: { [id: string]: drag.Drake } = {};

export const dragula = {
    init: (el: Element, dotnetHelper: DotNetReferenceType, options) => {
        const opts: drag.DragulaOptions = {
            invalid: function (el: HTMLElement, target: Element) {
                return el.dataset.readonly == "true" || el.tagName == "SELECT" || el.tagName == "OPTION";
            }
        };

        const d = drag([el], Object.assign({}, opts, options));

        d.on('drag', function (el: HTMLElement) {
            //dotnetHelper.invokeMethodAsync('DragulaDrag', el.id);
        });

        d.on('drop', function (el: HTMLElement, target: HTMLElement, source: HTMLElement) {
            const jqEl = $(source);
            const models = [];

            jqEl.find('[data-model]').each(function () {
                models.push($(this).data('model'));
            });

            dotnetHelper.invokeMethodAsync('DragulaDrop', JSON.stringify(models));
        });

        drakeCache[el.id] = d;
    },
    destroy: (el: Element) => {
        const id = el.id;
        const d = drakeCache[id];

        if (d) {
            d.destroy();

            delete drakeCache[id];
        }
    }
};