﻿import { DotNetReferenceType } from "./dotnet";

type DropdownInit = {
    click: (e: MouseEvent) => void;
};

const dropdownCache: { [id: string]: DropdownInit } = {};

export const material = {
    initSelect: (id: string) => {
        const el = $("#" + id);

        (el as any).dropdown({
            hover: false,
            closeOnClick: false
        });

        //(el as any).materialSelect();
    },
    destroySelect: (id: string) => {
        ($ as any)("#" + id).materialSelect({
            destroy: true
        });
    },
    initDatePicker: (id: string, options?: any) => {
        ($ as any)("#" + id).pickadate(options);
    },
    initTimePicker: (id: string, options?: any) => {
        ($ as any)("#" + id).pickatime(Object.assign({}, options, { twelvehour: true }));
    },
    initDropdown: (id: string, dropdownId: string, dotnet: DotNetReferenceType) => {
        const el = document.getElementById(id);

        if (el) {
            const onClick = (e: MouseEvent) => {
                document.removeEventListener('click', onClick);

                if (!el.contains(e.target as Node)) {
                    dotnet.invokeMethodAsync('ClickOut');
                }
            }

            document.addEventListener('click', onClick);

            dropdownCache[id] = {
                click: onClick
            };
        }
    },
    destoryDropdown: (id: string) => {
        const cached = dropdownCache[id];

        if (cached) {
            document.removeEventListener('click', cached.click);

            delete dropdownCache[id];
        }
    }
};