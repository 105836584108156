﻿import { UserManagerSettings, WebStorageStateStore, UserManager, User } from 'oidc-client';
import { DotNetReferenceType } from './dotnet';

import dayjs from 'dayjs';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { sentry } from './sentry';

type TokenResponse = {
    access_token: string
};

const host = window.location.host;

let checkExpiredHandle = null;

const settings: UserManagerSettings = {
    authority: import.meta.env.VITE_AUTH_AUTHORITY,
    client_id: import.meta.env.VITE_AUTH_CLIENT_ID,
    redirect_uri: `${window.location.protocol}//${host}${import.meta.env.VITE_AUTH_REDIRECT_URI}`,
    //post_logout_redirect_uri: `${window.location.protocol}//${host}${environment.auth.postLogoutRedirectUri}`,
    // silent_redirect_uri: `${window.location.protocol}//${host}__AUTH_SILENT_REDIRECT_URI__`,
    automaticSilentRenew: false,
    // includeIdTokenInSilentRenew: true,
    // response_type: 'code',
    revokeAccessTokenOnSignout: true,
    response_type: 'code',
    scope: 'openid profile api email offline_access',
    loadUserInfo: false,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    prompt: 'login',
};

const userManager = new UserManager(settings);

export const authentication = {
    init: async (dotnetHelper: DotNetReferenceType) => {
        const user = await userManager.getUser();
        const now = dayjs();
        const lastRefresh = authentication.getLastRefresh();
        let token = authentication.getToken();

        if (user && !user.expired) {
            if (!token) {
                token = user.access_token;
            } else if (lastRefresh && now.isAfter(dayjs.unix(lastRefresh).add(1, 'day'))) {
                try {
                    const refreshedToken = await authentication.refreshToken();

                    if (refreshedToken) {
                        token = refreshedToken;
                        user.access_token = token;

                        await userManager.storeUser(user);
                    }
                } catch (e) {
                    console.error(e);
                }
            }

            authentication.setToken(token, user.refresh_token);
            authentication.setIdToken(user.id_token);

            const { id, username } = authentication.getUser();

            sentry.setUser(id, username);

            await dotnetHelper.invokeMethodAsync('AuthCallback', true);

            checkExpiredHandle = setInterval(async () => {
                const { expired } = await userManager.getUser();

                if (expired) {
                    await authentication.logout();
                }
            }, 1000 * 60 * 5);
        } else {
            await dotnetHelper.invokeMethodAsync('AuthCallback', false);

            await authentication.login();
        }
    },
    login: async () => {
        await authentication.clearToken();
        await userManager.signinRedirect();
    },
    logout: async () => {
        if (checkExpiredHandle) {
            clearInterval(checkExpiredHandle);
        }

        await authentication.clearToken();
        await userManager.signoutRedirect();
    },
    isAuthenticated: () => {
        const token = authentication.getToken();

        return !!token && !authentication.isTokenExpired(token);
    },
    getToken: () => {
        return localStorage.getItem('access_token');
    },
    getIdToken: () => {
        return localStorage.getItem('id_token');
    },
    getRefreshToken: () => {
        return localStorage.getItem('refresh_token');
    },
    getLastRefresh: () => {
        const lastRefresh = localStorage.getItem('last_refresh');

        if (lastRefresh) {
            return JSON.parse(lastRefresh) as number;
        }

        return null;
    },
    setToken: (accessToken: string, refreshToken?: string) => {
        const oldAccessToken = authentication.getToken();

        localStorage.setItem('access_token', accessToken);

        if (refreshToken && accessToken !== oldAccessToken) {
            const now = dayjs();

            localStorage.setItem('refresh_token', refreshToken);
            localStorage.setItem('last_refresh', JSON.stringify(now.unix()));
        }
    },
    setIdToken: (idToken: string) => {
        localStorage.setItem('id_token', idToken);
    },
    refreshToken: async () => {
        const refreshToken = authentication.getRefreshToken();

        if (!refreshToken || !refreshToken.length) {
            return null;
        }

        const searchParams = new URLSearchParams({
            client_id: settings.client_id,
            client_secret: settings.client_secret,
            scope: settings.scope,
            grant_type: 'refresh_token',
            refresh_token: refreshToken
        });

        const response = await fetch(`${settings.authority}/connect/token`, {
            method: 'POST',
            body: searchParams
        });

        if (response.status !== 200) {
            throw new Error(await response.json());
        }

        const token = await response.json() as TokenResponse;

        return token.access_token;
    },
    clearToken: async () => {
        localStorage.removeItem('id_token');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('last_refresh');

        await Promise.all([
            userManager.clearStaleState(),
            userManager.removeUser()
        ]);
    },
    processToken: (token: string) => {
        const jwt = authentication.decodeToken(token);
        let userId = jwt.sub;
        let username = jwt.name;
        let roles = jwt.role || [];
        let permissions = jwt.permissions || [];
        let permTypes: { type: number, operationType: number }[] = [];

        if (typeof permissions === 'string') {
            permissions = [permissions];
        }

        for (let perm of Object.keys(permissions)) {
            let ex = permissions[perm].split('|');

            permTypes.push({
                type: parseInt(ex[0]),
                operationType: parseInt(ex[1])
            });
        }

        return {
            id: userId,
            username: username,
            roles: roles,
            permissions: permTypes
        };
    },
    getUser: () => {
        const token = authentication.getIdToken();

        if (!token) {
            return null;
        }

        return authentication.processToken(token);
    },
    decodeToken: (token: string) => {
        return jwt_decode<JwtPayload & { name: string, role: string[], permissions: string[] }>(token);
    },
    isTokenExpired: (token: string) => {
        const currentTime = Date.now() / 1000;
        const jwt = authentication.decodeToken(token);

        if (jwt.exp < currentTime) {
            return true;
        }

        return false;
    }
};