import { DotNetReferenceType } from "./dotnet";
import {
  googleapi,
  GoogleApiKey,
  GoogleClientId,
  GoogleAppId,
} from "./googleapi";
import { storage } from "./storage";

const DISCOVERY_DOCS = [
  // 'https://docs.googleapis.com/$discovery/rest?version=v1',
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
];

// const SCOPES = "https://www.googleapis.com/auth/drive.file";
const SCOPES = "https://www.googleapis.com/auth/drive.file";

let pickerLoaded = false;

type PickerCallbackData = {
  action: string;
  docs: (typeof google.picker.Document)[];
};

type AccessToken = {
  accessToken: string;
  expiresAt: number;
};

let accessToken: string | null = null;
let tokenClient: google.accounts.oauth2.TokenClient | null = null;

const initClient = async (dotnet: DotNetReferenceType) => {
  const googleToken = storage.getItem<AccessToken>("googleAccessToken");

  if (googleToken?.expiresAt > Date.now()) {
    accessToken = accessToken || googleToken?.accessToken;
  }

  //@ts-ignore
  await gapi.client.load(
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"
  );

  tokenClient = google.accounts.oauth2.initTokenClient({
    prompt: "",
    client_id: GoogleClientId,
    scope: SCOPES,
    callback: (response) => {
      accessToken = response.access_token;

      storage.setItem("googleAccessToken", {
        accessToken,
        expiresAt: Date.now() + parseInt(response.expires_in) * 1000,
      });

      openPicker(dotnet);
    },
  });

  pickerLoaded = true;

  await dotnet.invokeMethodAsync("GoogleDocsInit", googleapi.isLoggedIn());
};

const openPicker = (dotnet: DotNetReferenceType) => {
  const view = new google.picker.DocsView()
    .setIncludeFolders(true)
    .setSelectFolderEnabled(true)
    .setMimeTypes(
      "application/vnd.google-apps.folder,application/vnd.google-apps.document"
    )
    .setMode(google.picker.DocsViewMode.LIST);

  const picker = new google.picker.PickerBuilder()
    .addView(view)
    .enableFeature(google.picker.Feature.NAV_HIDDEN)
    .setAppId(GoogleAppId)
    .setDeveloperKey(GoogleApiKey)
    .setOAuthToken(accessToken)
    .setOrigin(window.location.protocol + "//" + window.location.host)
    .setCallback((data) => onPickerAction(dotnet, data))
    .build();

  picker.setVisible(true);
};

const onPickerAction = async (
  dotnet: DotNetReferenceType,
  data: PickerCallbackData
) => {
  if (data.action === google.picker.Action.PICKED) {
    const [doc] = data.docs;
    const { id, name } = doc as any;

    dotnet.invokeMethodAsync("GoogleDocSelected", id, name);
  }
};

const StylePropertiesToRemove = [
  "margin",
  "padding",
  "color",
  "backgroundColor",
  "fontSize",
  "fontFamily",
  "lineHeight",
  "orphans",
  "widows",
  "verticalAlign",
  "height",
  "width",
];

const parseAndFormatDoc = (html: string) => {
  const parser = new DOMParser();
  const { body } = parser.parseFromString(html, "text/html");

  const elements = body.querySelectorAll<HTMLElement>("*");

  for (const el of Array.from(elements)) {
    if (!el) {
      continue;
    }

    const style = el.style;

    for (const property of Object.keys(style)) {
      const value = style[property];

      if (
        typeof value !== "undefined" &&
        StylePropertiesToRemove.includes(property)
      ) {
        style[property] = null;
      } else if (property === "textAlign" && value === "justify") {
        style[property] = null;
      }
    }

    // Remove excess new lines
    if (el.tagName === "P" && el.children.length === 1) {
      const child = el.children[0];

      if (child.tagName === "BR" || child.textContent.trim() === "") {
        el.remove();

        continue;
      }
    }

    if (el.tagName === "P" && el.nextElementSibling?.tagName === "BR") {
      el.nextElementSibling.remove();
    } else if (el.tagName === "BR" && el.nextElementSibling?.tagName === "BR") {
      el.nextElementSibling.remove();
    }
  }

  return body.innerHTML;
};

export const gdocs = {
  init: (dotnet: DotNetReferenceType) => {},
  exportDoc: async (dotnet: DotNetReferenceType, id: string) => {
    const { body } = await gapi.client.drive.files.export({
      fileId: id,
      mimeType: "text/html",
      oauth_token: accessToken,
    });

    const html = parseAndFormatDoc(body);

    dotnet.invokeMethodAsync("GoogleDocExported", html);
  },
  openPicker: async (dotnet: DotNetReferenceType) => {
    if (!pickerLoaded) {
      console.warn("Google picker not loaded yet!");

      gapi.load("client:picker", () =>
        initClient(dotnet).then(() => {
          if (!accessToken) {
            tokenClient.requestAccessToken();
          } else {
            openPicker(dotnet);
          }
        })
      );

      return;
    }

    try {
      openPicker(dotnet);
    } catch (e) {
      tokenClient.requestAccessToken();
    }
  },
};
