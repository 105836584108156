﻿import './css/tailwind.css';
import './vendor/vendor.scss';

declare var Blazor: any;

import { app } from './app';
import { authentication } from './authentication';
import { datetimepicker } from './datetimepicker';
import { dialogs } from './dialogs';
import { dragula } from './dragula';
import { editor } from './editor';
import { events } from './events';
import { markdown } from './markdown';
import { tables } from './tables';
import { toastr } from './toastr';
import { trees } from './trees';
import { utils } from './utils';
import { typeahead } from './typeahead';
import { material } from './material';
import { analytics } from './analytics';
import { charts } from './charts';
import { push } from './push';
import { storage } from './storage';
import { theme } from './theme';
import { calendar } from './calendar';
import { googleapi } from './googleapi';
import { gdocs } from './gdocs';
import { http } from './http';
import { audiobooks } from './audiobooks';
import { sentry } from './sentry';

sentry.init();

//var $ = require('jquery');
//require('imports-loader?define=>false!datatables.net-bs4')(window, $);
// require('datatables.net-bs4');
// require('moment');
// require('tempusdominus-bootstrap-4');
// require('toastr');

import 'jquery';
import 'bootstrap';
import 'tempusdominus-bootstrap-4';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat)

const environmentName: string = import.meta.env.VITE_ENV as string;
const originalFetch = fetch;

window.fetch = async (url: string, options) => {
    const response = await originalFetch(url, options);

    return url.endsWith('/blazor.boot.json')
        ? new Response(await response.blob(), { headers: { 'blazor-environment': environmentName } })
        : response;
};

const adminFunctions = {
    app,
    audiobooks,
    analytics,
    authentication,
    charts,
    dialogs,
    editor,
    markdown,
    datetimepicker,
    utils,
    toastr,
    trees,
    tables,
    dragula,
    events,
    typeahead,
    material,
    push,
    storage,
    theme,
    calendar,
    googleapi,
    gdocs,
    http,
    sentry
};

adminFunctions.app.preInit();

window['adminFunctions'] = adminFunctions;

Blazor.start({
    loadBootResource: function (type: string, name: string, defaultUri: string, integrity: string) {
        if (environmentName === 'Development') {
            return undefined;
        }

        // switch (type) {
        //     case 'dotnetwasm':
        //     case 'timezonedata':
        //         return `https://d13jb055y3kglj.cloudfront.net/blazorwebassembly/6/${name}.br`;
        // }
    }
});