import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { Wasm as WasmIntegration } from "@sentry/wasm";

export const sentry = {
    init: () => {
        const dsn = import.meta.env.VITE_SENTRY_DSN;

        if (!dsn) {
            return;
        }

        Sentry.init({
            dsn,
            integrations: [
                new Integrations.ExtraErrorData(),
                new WasmIntegration()
            ],
            release: '0.1'
        });
    },
    setUser: (id: string, username: string) => {
        Sentry.setUser({
            id,
            username
        });
    }
}