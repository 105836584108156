import { DotNetReferenceType, DotNet } from "./dotnet";
import { authentication } from "./authentication";
import dayjs from 'dayjs';
import { Novel, Row, Column } from "./data";
import { charts } from "./charts";
import { tables } from "./tables";

type AnalyticsToken = {
    token: string;
    viewId: string;
};

type ComponentOptions = {
    container: string | HTMLElement;
    pollingInterval: number;
    ids: string;
    filters: string;
};

const getGa = () => (gapi.client as any).analytics.data.ga as gapi.client.analytics.GaResource;
const getRealTime = () => (gapi.client as any).analytics.data.realtime as gapi.client.analytics.RealtimeResource;

const createEmbedComponent = (opts: ComponentOptions) => {
    const component = {
        _stopped: false,
        _polling: false,
        _timeout: null,
        _container: null as HTMLElement,
        _animationTimeout: null,
        activeUsers: 0,
        execute: () => {
            if (component._stopped) {
                return;
            }

            // Stop any polling currently going on.
            if (component._polling) {
                component.stop();
            }

            component._polling = true;

            component.render();

            component._pollActiveUsers();
        },
        stop: () => {
            clearTimeout(component._timeout);
            component._stopped = true;
            component._polling = false;
        },
        render: () => {
            // Render the component inside the container.
            component._container = typeof opts.container == 'string' ?
                document.getElementById(opts.container) : opts.container;

            if (component._container) {
                component._container.innerHTML = component.template;
                component._container.querySelector('b').innerHTML = component.activeUsers.toString();
            }
        },
        _pollActiveUsers: () => {
            let pollingInterval = (opts.pollingInterval || 5) * 1000;

            if (isNaN(pollingInterval) || pollingInterval < 5000) {
                throw new Error('Frequency must be 5 seconds or more.');
            }

            getRealTime()
                .get({ ids: opts.ids, metrics: 'rt:activeUsers', filters: opts.filters })
                .then((response) => {
                    const result = response.result;
                    const newValue = result.totalResults ? +result.rows[0][0] : 0;
                    const oldValue = component.activeUsers;

                    if (newValue != oldValue) {
                        component.activeUsers = newValue;
                        component._onChange(newValue - oldValue);
                    }

                    if (component._polling === true) {
                        component._timeout = setTimeout(component._pollActiveUsers,
                            pollingInterval);
                    }
                });
        },

        _onChange: (delta: number) => {
            if (component._container) {
                let valueContainer = component._container.querySelector('b');
                if (valueContainer) valueContainer.innerHTML = component.activeUsers.toString();

                const element = component._container.firstChild as HTMLElement;
                const animationClass = delta > 0 ? 'is-increasing' : 'is-decreasing';
                element.className += (' ' + animationClass);

                clearTimeout(component._animationTimeout);

                component._animationTimeout = setTimeout(function () {
                    element.className =
                        element.className.replace(/ is-(increasing|decreasing)/g, '');
                }, 3000);
            }
        },

        template:
            '<div class="ActiveUsers">' +
            'Active Users: <b class="ActiveUsers-value"></b>' +
            '</div>',

    };

    return component;
}

const executeGaRequest = <T>(request: gapi.client.Request<T>) => {
    return new Promise<T>((resolve, reject) => {
        request.execute(resp => {
            resolve(resp.result);
        });
    });
}

const initGoogleApi = (token: string) => {
    return new Promise((resolve, reject) => {
        gapi.load('auth', () => {
            if (token) {
                gapi.auth.setToken({ access_token: token } as gapi.auth.GoogleApiOAuth2TokenObject);
            }

            gapi.load('client', () => {
                gapi.client.load('analytics', 'v3', () => {
                    resolve(null);
                });
            });
        });
    });
};

const numberWithCommas = (x: number | string): string => {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

const calculateCpm = (novel: Novel, viewCount: number): number => {
    if (novel) {
        return novel.cpm * (viewCount / 1000.0);
    }

    return 0;
}

const analyticsCache: { [id: number]: (() => void) } = {};

export const analytics = {
    init: async (novel: Novel, token: AnalyticsToken, dotnetHelper: DotNetReferenceType) => {
        const embedComponent = createEmbedComponent({
            container: 'active-users-container',
            pollingInterval: 5,
            filters: `rt:pagePath=~/(.*)/${novel?.slug}`,
            ids: token.viewId
        });

        analyticsCache[novel?.id ?? 0] = () => {
            embedComponent.stop();

            charts.destroy('analytics-chart');
        };

        await initGoogleApi(token.token);

        if (novel) {
            embedComponent.execute();
        }

        const currentUser = authentication.getUser();
        const currentDate = dayjs();
        const lastYear = currentDate.clone().subtract(1, 'year');

        const baseReq = {
            'ids': token.viewId
        };

        let monthDateReq = Object.assign({}, baseReq, {
            'dimensions': 'ga:date',
            'start-date': '30daysAgo',
            'end-date': 'today',
            'metrics': 'ga:pageviews'
        });

        let currentMonthReq = Object.assign({}, baseReq, {
            'metrics': 'ga:pageviews',
            'start-date': `${currentDate.format('YYYY-MM')}-01`,
            'end-date': 'today'
        });

        let currentYearReq = Object.assign({}, baseReq, {
            'dimensions': 'ga:month,ga:year',
            'metrics': 'ga:pageviews',
            'start-date': `${lastYear.format('YYYY-MM')}-01`,
            'end-date': 'today'
        });

        let filters = [];

        if (!novel) {
            filters.push(`ga:dimension2==${currentUser.username}`);
        } else {
            filters.push(`ga:dimension4==${novel.slug}`);
        }

        if (filters.length) {
            let filtersStr = filters.join(';');

            monthDateReq['filters'] = filtersStr;
            currentMonthReq['filters'] = filtersStr;
            currentYearReq['filters'] = filtersStr;
        }

        const responses = await Promise.all([
            executeGaRequest(getGa().get(monthDateReq)),
            executeGaRequest(getGa().get(currentMonthReq)),
            executeGaRequest(getGa().get(currentYearReq))
        ]);

        const monthDateResp = responses[0];
        const currentMonthResp = responses[1];
        const currentYearResp = responses[2];

        const currentMonthViews = currentMonthResp.rows?.length > 0 ? parseInt(currentMonthResp.rows[0][0]) : 0;

        $("#month-pageviews").html(`<span><strong>Current Month's Page Views:</strong> ${numberWithCommas(currentMonthViews)}</span>`);

        const monthDateRows = monthDateResp.rows || [];

        const dateTblCols: Column[] = [
            {
                title: 'Date',
                visible: true
            },
            {
                title: 'Page Views',
                visible: true
            }
        ];

        const yearTblCols: Column[] = [
            {
                title: 'Month',
                visible: true
            },
            {
                title: 'Page Views',
                visible: true
            }
        ];

        const dateTblRows = monthDateRows
            .map<Row>(row => {
                const dt = dayjs(row[0], 'YYYYMMDD');

                return {
                    data: {
                        row,
                        date: dt
                    },
                    cellInfos: [
                        {
                            data: dt.format('LL')
                        },
                        {
                            data: numberWithCommas(row[1])
                        }
                    ]
                }
            }).sort((a, b) => a.data.date.unix() - b.data.date.unix());

        const yearTblRows = currentYearResp.rows
            .map(row => {
                const dt = dayjs(row[0] + row[1], 'MMYYYY');

                return {
                    data: {
                        row,
                        date: dt
                    },
                    cellInfos: [
                        {
                            data: dt.format('MMMM, YYYY')
                        },
                        {
                            data: numberWithCommas(row[2])
                        }
                    ]
                };
            }).sort((a, b) => a.data.date.unix() - b.data.date.unix());

        tables.init('pageviews-date-tbl', DotNet.NoopRef, dateTblCols, dateTblRows);
        tables.init('pageviews-year-tbl', DotNet.NoopRef, yearTblCols, yearTblRows);

        const rows = monthDateRows.map(m => [m[0], numberWithCommas(m[1])]);

        charts.init('analytics-chart', {
            type: 'line',
            data: {
                labels: rows.map(r => dayjs(r[0]).toDate().toDateString()),
                datasets: [
                    {
                        label: 'Views',
                        data: monthDateRows.map(r => parseInt(r[1])),
                        borderColor: 'rgb(30, 144, 255)'
                        // data: monthDateRows.map(r => Number(r[1])).reduce<number[]>((p, c) => {
                        //     const cpm = calculateCpm(novel, p.reduce((a, b) => a + b, 0) + c);

                        //     return [...p, cpm];
                        // }, [])
                    }
                ]
            },
            options: {
                responsive: true,
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: (value, index, values) => {
                                return numberWithCommas(value);
                            }
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label({ dataset, dataIndex }) {
                                let label = dataset.label || '';

                                if (label) {
                                    label += ': ';
                                }

                                label += numberWithCommas(dataset.data[dataIndex].toString());

                                return label;
                            }
                        }
                    }
                }
            },
        });
    },
    destroy: (novel: Novel) => {
        analyticsCache[novel?.id ?? 0]?.();
    }
};