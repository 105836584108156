﻿export type DotNetType = {
    invokeMethod<T>(assemblyName: string, methodIdentifier: string, ...args: any[]): T,
    invokeMethodAsync<T>(assemblyName: string, methodIdentifier: string, ...args: any[]): Promise<T>
}

export type DotNetReferenceType = {
    invokeMethod<T>(methodIdentifier: string, ...args: any[]): T,
    invokeMethodAsync<T>(methodIdentifier: string, ...args: any[]): Promise<T>
}

const NoopDotNetRef: DotNetReferenceType = {
    invokeMethod<T>(methodIdentifier: string, ...args: any[]): T { return null; },
    invokeMethodAsync<T>(methodIdentifier: string, ...args: any[]): Promise<T> { return Promise.resolve<T>(null); },
};

export const DotNet = {
    NoopRef: NoopDotNetRef
};