﻿import toast from 'toastr';
import { DotNetReferenceType } from './dotnet';

type ToastOptions = ToastrOptions & {
    requiresInteraction: boolean
};

let helper: DotNetReferenceType = null;

const createToastrOptions = (id: string, options: ToastOptions): ToastrOptions => {
    const {
        requiresInteraction,
        ...opts
    } = options || {} as ToastOptions;

    const timeOut = requiresInteraction ? 0 : opts.timeOut || undefined;
    const extendedTimeOut = requiresInteraction ? 0 : opts.extendedTimeOut || undefined;

    return {
        ...opts,
        timeOut,
        extendedTimeOut,
        onclick: () => {
            helper?.invokeMethodAsync('ToastrOnClick', id);
        },
        onHidden: () => {
            helper?.invokeMethodAsync('ToastrOnHidden', id);
        }
    };
};

export const toastr = {
    init: (dotnetHelper: DotNetReferenceType) => {
        helper = dotnetHelper;
    },
    info: (id: string, message: string, title: string, options: ToastOptions) => {
        toast.info(message, title, createToastrOptions(id, options));
    },
    success: (id: string, message: string, title: string, options: ToastOptions) => {
        toast.success(message, title, createToastrOptions(id, options));
    },
    warning: (id: string, message: string, title: string, options: ToastOptions) => {
        toast.warning(message, title, createToastrOptions(id, options));
    },
    error: (id: string, message: string, title: string, options: ToastOptions) => {
        toast.warning(message, title, createToastrOptions(id, options));
    }
};