﻿import dayjs from 'dayjs';
import { DotNetReferenceType } from './dotnet';

export const datetimepicker = {
    init: (id: string, dotnetHelper: DotNetReferenceType, options) => {
        ($ as any)(`#${id}`).datetimepicker(options);
        $(`#${id}`).on('change.datetimepicker', (e, eventInfo) => {
            const evt = e as any;

            const dt: dayjs.Dayjs = evt.date;
            let unix = null;

            if (dt.isValid()) {
                unix = dt.unix();
            }

            dotnetHelper.invokeMethodAsync('DateChanged', unix);
        });
    },
    setDate: (id: string, date?: number) => {
        if (!date) {
            ($ as any)(`#${id}`).datetimepicker('clear');
        } else {
            const m = dayjs.unix(date);

            (<any>$)(`#${id}`).datetimepicker('date', m.toDate());
        }
    },
    destroy: (id: string) => {
        $(`#${id}`).off('change.datetimepicker');
        ($ as any)(`#${id}`).datetimepicker('destroy');
    }
};