import { DotNetReferenceType } from "./dotnet";

export const GoogleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
export const GoogleApiKey = import.meta.env.VITE_GOOGLE_API_KEY;
export const GoogleAppId = import.meta.env.VITE_GOOGLE_APP_ID;

export const onGoogleLogin = (dotnet: DotNetReferenceType, result: boolean) => {
  return dotnet.invokeMethodAsync("GoogleAuth", result);
};

let credential: string | null = null;

export const googleapi = {
  init: async (dotnet: DotNetReferenceType) => {
    try {
      google.accounts.id.initialize({
        client_id: GoogleClientId,
        callback: (response) => {
          credential = response.credential;

          dotnet.invokeMethodAsync("GoogleAuth", !!credential);
        },
        itp_support: true,
        auto_select: true,
      });

      dotnet.invokeMethodAsync("GoogleInit", true);
    } catch (e) {
      console.error(e);
    }
  },
  renderLoginButton: (
    dotnet: DotNetReferenceType,
    id: string,
    scopes: string
  ) => {
    google.accounts.id.renderButton(document.getElementById(id), {
      type: "standard",
      theme: "filled_blue",
    });

    google.accounts.id.prompt();
  },
  login: async (dotnet: DotNetReferenceType) => {
    try {
      //   const user = await gapi.auth2.getAuthInstance().signIn();

      google.accounts.id.prompt();
    } catch (e) {
      console.error(e);

      await dotnet.invokeMethodAsync("GoogleAuth", false);
    }
  },
  isLoggedIn: () => {
    // return gapi.auth2.getAuthInstance().isSignedIn.get();
    // return !!credential;
    return true;
  },
  getAccessToken: async () => {
    // if (!(await googleapi.isLoggedIn())) {
    //   return null;
    // }
    return credential;
  },
};
